import { sendDataLayer } from "jiffy-analytics";
import getMetaEnv from "common/utils/meta";
import idleCallback, { timings } from "common/utils/ui/idle_callback";
import { waitForWindowVariable } from "common/utils/ui/wait_for";

const MAX_ATTEMPTS = 10;
const POLL_INTERVAL = 1000;
const FOCUS_INTERVAL = 300;

const getFrame = () => {
  let attempt = 0;
  return new Promise(resolve => {
    (function poll() {
      const frame = document.querySelector(".js-ratingbadge iframe");
      if (frame) {
        resolve(frame);
      } else if (attempt > MAX_ATTEMPTS) {
        resolve(null);
      } else {
        attempt += 1;
        setTimeout(poll, POLL_INTERVAL * attempt);
      }
    })();
  });
};

document.addEventListener("DOMContentLoaded", async () => {
  const reviewBox = await getFrame();
  const userId = getMetaEnv("user_id");
  if (!reviewBox) return;

  const checkFocus = () => {
    if (document.activeElement === reviewBox) {
      sendDataLayer({
        genericEvent: true,
        eventCategory: "Block: Google customer reviews",
        event: "click",
        eventLabel: userId ? "user" : "guest"
      });
      window.focus();
    }
  };
  window.setInterval(checkFocus, FOCUS_INTERVAL);
});

const executeTrustedStores = props => {
  const ratingBadgeContainer = document.createElement("div");
  if (props.ui_rating_badge_analytics) {
    ratingBadgeContainer.classList.add("js-ratingbadge");
  }

  document.body.appendChild(ratingBadgeContainer);
  window.gapi.load("ratingbadge", () => {
    window.gapi.ratingbadge.render(ratingBadgeContainer, {
      // REQUIRED
      merchant_id: props.merchant_id,
      // OPTIONAL
      position: props.position
    });
  });
};

const initTrustedStoresScript = () => {
  const script = document.createElement("script");
  script.async = true;

  script.src = "https://apis.google.com/js/platform.js?onload=renderBadge";
  document.body.appendChild(script);
};

window.addEventListener("load", () => {
  idleCallback(initTrustedStoresScript, timings.trustedStores);
  waitForWindowVariable(
    "gapi",
    () => {
      executeTrustedStores(window.trustedStoresConfig);
    },
    () => {
      console.error("TrustedStores is not loaded"); // eslint-disable-line no-console
    }
  );
});
